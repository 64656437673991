import { array, number, object, string } from 'zod';

export interface Record {
  id: string;
  env_id: string;

  name: string;
  endpoint: string;
  timeout: number;
  max_retry: number;
  event_types: Array<string>;

  created_at: string;
  updated_at: string;
}

export interface Records extends Array<Record> {}

export interface GetDestinationResponse {
  records: Records;
  total_destination_count: number;
  total_switcher_count: number;
}

export const GetDestinationSchema = object({
  records: array(
    object({
      id: string(),
      env_id: string(),
      name: string(),
      endpoint: string().url(),
      timeout: number().nonnegative(),
      max_retry: number().nonnegative(),
      event_types: string().array(),
      created_at: string(),
      updated_at: string(),
    }),
  ),
  total_destination_count: number(),
  total_switcher_count: number(),
});
