import { Box, Text } from '@chakra-ui/react';

import { ContentBox } from '@/pages/common';

export const BlankData = () => {
  return (
    <ContentBox px="16px" py="16px">
      <Box py="8px" textAlign="center">
        <Text fontSize="lg" fontWeight="bold" marginBottom="8px">
          対象がありません。
        </Text>
        <Text fontSize="md">
          右上の[作成する]ボタンから外部連携先を作成してください。
        </Text>
      </Box>
    </ContentBox>
  );
};
