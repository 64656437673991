'use client';

import { WebAuth } from 'auth0-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { MAX_DESTINATION_COUNT } from '@/constants';
import useGetRequest from '@/hooks/useGetRequest';
import { EnvironmentIdState } from '@/recoil';
import { GetDestinationSchema } from '@/schemas/GetDestination.schema';

import {
  ApiErrorComponent,
  Content,
  Main,
  SubHeading,
  useAlert,
} from '@/pages/common';

import { AxiosError } from 'axios';
import { logout } from '../common/services/auth';
import { BlankData } from './destination/list/components/BlankData';
import { TableContents } from './destination/list/components/TableContents';

interface PageProps {
  authSession: string;
  authService?: WebAuth;
}

const endpoint = '/destination';

export default function App({ authSession, authService }: PageProps) {
  const router = useRouter();

  const environmentId = useRecoilValue(EnvironmentIdState);

  const [isInitLoading, setInitLoading] = useState(true);
  const [isApiError, setIsApiError] = useState(false);
  const [isCreateLoading, setCreateLoading] = useState(false);
  const [totalDestinationCount, setTotalDestinationCount] = useState(0);
  const [totalSwitcherCount, setTotalSwitcherCount] = useState(0);

  const { addToast } = useAlert();

  const {
    data,
    mutate,
    isLoading: isDataLoading,
    isError,
  } = useGetRequest(
    'tact',
    environmentId ? `${endpoint}/${environmentId}` : null,
    GetDestinationSchema,
    authSession,
  );

  const onCreateClick = () => {
    setCreateLoading(true);
    router.push(`${environmentId}/destination/create`);
  };

  const showBlankPage = () => {
    if (environmentId && !isDataLoading) {
      if (isError || (data && !data.records.length)) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (sessionStorage.getItem('from_P12')) {
      addToast({
        title: '外部連携先削除完了',
        message: '外部連携先の削除が完了しました。',
      });
      sessionStorage.removeItem('from_P12');
    }
  }, []);

  useEffect(() => {
    if (!isDataLoading) {
      setInitLoading(false);
    }
  }, [isDataLoading]);

  useEffect(() => {
    if (data) {
      setTotalDestinationCount(data.total_destination_count || 0);
      setTotalSwitcherCount(data.total_switcher_count || 0);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      if (isError instanceof AxiosError || (isError as any).response) {
        const { status } = (isError as any).response;

        if (status === 401) {
          if (authService) logout(authService!);
        } else if (status === 403 || status === 404) {
          window.location.href = '/404';
        } else {
          // Handle error in inner component
          setIsApiError(true);
        }
      }
    } else {
      setIsApiError(false);
    }
  }, [isError]);

  return (
    <Main
      isContentLoading={environmentId === null && isInitLoading}
      authSession={authSession}
      authService={authService}
    >
      {isApiError ? (
        <ApiErrorComponent reloadFn={mutate} />
      ) : (
        <Content
          isRestrictedHeight
          heading="外部連携先一覧"
          hasTopRightButton
          topRightButtonLabel="作成する"
          topRightButtonAction={onCreateClick}
          topRightButtonLoading={isCreateLoading}
          topRightButtonDisabled={
            totalDestinationCount >= MAX_DESTINATION_COUNT
          }
          subheading={
            <SubHeading
              isLoading={isDataLoading}
              totalDestinationCount={totalDestinationCount}
              totalSwitcherCount={totalSwitcherCount}
            />
          }
        >
          {showBlankPage() ? (
            <BlankData />
          ) : (
            <TableContents data={data} isTableLoading={isDataLoading} />
          )}
        </Content>
      )}
    </Main>
  );
}
