'use client';

import { Box } from '@chakra-ui/react';
import { useState } from 'react';
import { ResizableBox } from 'react-resizable';

import { ContentBox, Table, TableContainer } from '@/pages/common';
import { GetDestinationResponse } from '@/schemas/GetDestination.schema';

import { TableData } from './TableData';

type Props = {
  data?: GetDestinationResponse;
  isTableLoading?: boolean;
};

const TABLE_PADDING = 24;
const TABLE_COLUMN_WIDTHS = [280, 350, 130, 160, 180, 160, 160].map(
  (i) => i - TABLE_PADDING,
);

const ResizableTh = ({
  width,
  onResize,
  children,
  minConstraints,
  className,
}) => (
  <ResizableBox
    width={width}
    height={15}
    axis="x"
    resizeHandles={['e']}
    handle={<div className={className}>|</div>}
    onResize={(_, { size }) => onResize(size.width)}
    style={{ display: 'flex' }}
    minConstraints={minConstraints}
  >
    <Box display="inline-block" width={width}>
      {children}
    </Box>
  </ResizableBox>
);

export const TableContents = (props: Props) => {
  const { data, isTableLoading } = props;

  const [columnWidths, setColumnWidths] = useState([...TABLE_COLUMN_WIDTHS]);

  const handleResize = (index, newWidth) => {
    const newWidths = [...columnWidths];
    newWidths[index] = newWidth;
    setColumnWidths(newWidths);
  };

  return (
    <ContentBox flexGrow={1} px="16px" py="16px">
      <TableContainer>
        <Table.Header>
          <Table.Row>
            <Table.Heading width={columnWidths[0]} className="stickyColumnLeft">
              <ResizableTh
                width={columnWidths[0]}
                onResize={(newWidth) => handleResize(0, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[0], Infinity]}
                className="customResizeHandler"
              >
                名前
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[1]}>
              <ResizableTh
                width={columnWidths[1]}
                onResize={(newWidth) => handleResize(1, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[1], Infinity]}
                className="customResizeHandler"
              >
                エンドポイント
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[2]}>
              <ResizableTh
                width={columnWidths[2]}
                onResize={(newWidth) => handleResize(2, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[2], Infinity]}
                className="customResizeHandler"
              >
                タイムアウト
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[3]}>
              <ResizableTh
                width={columnWidths[3]}
                onResize={(newWidth) => handleResize(3, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[3], Infinity]}
                className="customResizeHandler"
              >
                最大リトライ回数
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[4]}>
              <ResizableTh
                width={columnWidths[4]}
                onResize={(newWidth) => handleResize(4, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[4], Infinity]}
                className="customResizeHandler"
              >
                イベントタイプ
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[5]}>
              <ResizableTh
                width={columnWidths[5]}
                onResize={(newWidth) => handleResize(5, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[5], Infinity]}
                className="customResizeHandler"
              >
                作成日時
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={columnWidths[6]}>
              <ResizableTh
                width={columnWidths[6]}
                onResize={(newWidth) => handleResize(6, newWidth)}
                minConstraints={[TABLE_COLUMN_WIDTHS[6], Infinity]}
                className="customResizeHandler"
              >
                更新日時
              </ResizableTh>
            </Table.Heading>
            <Table.Heading width={80} className="stickyColumnRight" />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {isTableLoading && <Table.Progress colSpan={8} />}
          {data?.records.map((record) => (
            <TableData key={record.id} record={record} />
          ))}
        </Table.Body>
      </TableContainer>
    </ContentBox>
  );
};
