import { TableRowProps, Text } from '@chakra-ui/react';
import { formatInTimeZone } from 'date-fns-tz';
import Image from 'next/image';
import { MouseEvent } from 'react';

import Table from '@/pages/common/table';
import { EnvironmentIdState } from '@/recoil';
import { Record } from '@/schemas/GetDestination.schema';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import styles from './TableData.module.css';

interface Props extends TableRowProps {
  record: Record;
}

export function TableData(props: Props) {
  const { record, ...otherProps } = props;

  const router = useRouter();
  const environmentId = useRecoilValue(EnvironmentIdState);

  const handleDetailClick = (id: string) => {
    router.push(`${environmentId}/destination/detail/${id}`);
  };

  const handleEditClick = (evt: MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    router.push(`${environmentId}/destination/edit/${record.id}`);
  };

  const formatTimeout = (timeout: number) => {
    if (timeout % 5 || timeout > 30 || timeout < 5) {
      return `その他 (${timeout}秒)`;
    }

    return `${timeout}秒`;
  };

  return (
    <Table.Row
      cursor="pointer"
      className={styles.customTableStyle}
      onClick={() => handleDetailClick(record.id)}
      {...otherProps}
    >
      <Table.Column
        minWidth="100px"
        maxWidth="150px"
        wordBreak="break-word"
        whiteSpace="pre-wrap"
        className="stickyColumnLeft"
      >
        {record.name}
      </Table.Column>
      <Table.Column
        minWidth="180px"
        wordBreak="break-all"
        whiteSpace="pre-wrap"
      >
        {record.endpoint}
      </Table.Column>
      <Table.Column minWidth="100px" width="100px">
        {formatTimeout(record.timeout)}
      </Table.Column>
      <Table.Column minWidth="130px" width="130px">
        {record.max_retry !== undefined || record.max_retry !== null
          ? `${record.max_retry}回`
          : ''}
      </Table.Column>
      <Table.Column minWidth="150px" width="150px">
        {!record.event_types.length && '未設定'}
        {record.event_types.map((eventType) => (
          <Text key={`${record.id}__${eventType}`}>{eventType}</Text>
        ))}
      </Table.Column>
      <Table.Column minWidth="130px" width="130px">
        {formatInTimeZone(
          new Date(record.created_at),
          'Japan',
          'yyyy/MM/dd HH:mm',
        )}
      </Table.Column>
      <Table.Column minWidth="130px" width="130px">
        {formatInTimeZone(
          new Date(record.updated_at),
          'Japan',
          'yyyy/MM/dd HH:mm',
        )}
      </Table.Column>
      <Table.Column
        minWidth="60px"
        width="60px"
        p={0}
        data-testid="table-row-edit"
        onClick={handleEditClick}
        className="stickyColumnRight"
      >
        <Image
          src="/icons/pen.svg"
          alt="edit"
          width={20}
          height={20}
          style={{ margin: '0 auto' }}
        />
      </Table.Column>
    </Table.Row>
  );
}
